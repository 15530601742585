import { Directive, Input, OnChanges, SimpleChanges, ElementRef, Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import {RouterOutlet, Router} from '@angular/router';
import { EC2Service } from '../../service/ec2.service';
import { environment } from '../../../environments/environment';
import { CloudWatchLogService } from 'src/app/service/cw.service';
import { merge, Observable, of as observableOf } from 'rxjs';
import { DatePipe } from '@angular/common';
import { MatProgressButtonOptions } from 'mat-progress-buttons';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

export class Stuff {
    public log: string;
}

export interface DialogData {
  buttonName: string;
  buttonValue: string;
}

@Component({
    templateUrl: './jispt.component.html',
    styleUrls: ['../secure.component.scss', 'jispt.component.scss']
})
export class JisptComponent implements OnInit, AfterViewInit {

    buttonValue: string;
    statusBastionValue: any;
    statusCplexValue: any;
    statusUiValue: any;
    statusRdsValue: any;
    statusAllValue: any;
    public flag;
    public logs: any;
    isUi: boolean = false;
    isBastion: boolean = false;
    isCplex: boolean = false;
    isRDS: boolean =false;
    isAll: boolean = false;
    rdsResponse:any; 
    instancesList: any;
    rdsData: any;
    rdsData1: any;
    statusBastionColor: any;
    statusCplexColor: any;
    statusUiColor: any;
    statusRdsColor: any;
    statusAllColor: any;
    rdsStatusNotChanged: boolean = false;
    // public logdata: Array<Stuff> = [];
    columnsToDisplay = ['date', 'message'];
    isLoadingResults = false;
    public bastionState: BehaviorSubject<string> = new BehaviorSubject('');
    public cplexState: BehaviorSubject<string> = new BehaviorSubject('');
    public uiState: BehaviorSubject<string> = new BehaviorSubject('');
    public rdsState: BehaviorSubject<string> = new BehaviorSubject('');
    public allState: BehaviorSubject<string> = new BehaviorSubject('');

    startUiButtonOptions: MatProgressButtonOptions = {
        active: false,
        text: 'Start',
        spinnerSize: 20,
        raised: true,
        stroked: false,
        spinnerColor: 'accent',
        fullWidth: true,
        disabled: false,
        mode: 'indeterminate',
         customClass: 'start-class',
    };

    stopUiButtonOptions: MatProgressButtonOptions = {
        active: false,
        text: 'Stop',
        spinnerSize: 20,
        raised: true,
        stroked: false,
        spinnerColor: 'accent',
        fullWidth: false,
        disabled: this.flag,
        mode: 'indeterminate',
        customClass: 'stop-class',
         
    };

    startCplexButtonOptions: MatProgressButtonOptions = {
        active: false,
        text: 'Start',
        spinnerSize: 20,
        raised: true,
        stroked: false,
        spinnerColor: 'accent',
        fullWidth: true,
        disabled: false,
        mode: 'indeterminate',
         customClass: 'start-class',
    };

    stopCplexButtonOptions: MatProgressButtonOptions = {
        active: false,
        text: 'Stop',
        spinnerSize: 20,
        raised: true,
        stroked: false,
        spinnerColor: 'accent',
        fullWidth: true,
        disabled: this.flag,
        mode: 'indeterminate',
        customClass: 'stop-class',
    };

    startBastionButtonOptions: MatProgressButtonOptions = {
        active: false,
        text: 'Start',
        spinnerSize: 20,
        raised: true,
        stroked: false,
        spinnerColor: 'accent',
        fullWidth: true,
        disabled: false,
        mode: 'indeterminate',
        customClass: 'start-class',
    };

    stopBastionButtonOptions: MatProgressButtonOptions = {
        active: false,
        text: 'Stop',
        spinnerSize: 20,
        raised: true,
        stroked: false,
        spinnerColor: 'accent',
        fullWidth: true,
        disabled: this.flag,
        mode: 'indeterminate',
        customClass: 'stop-class',
    };

    startRDSButtonOptions: MatProgressButtonOptions = {
        active: false,
        text: 'Start',
        spinnerSize: 20,
        raised: true,
        stroked: false,
        spinnerColor: 'accent',
        fullWidth: true,
        disabled: false,
        mode: 'indeterminate',
        customClass: 'start-class',
    };

    stopRDSButtonOptions: MatProgressButtonOptions = {
        active: false,
        text: 'Stop',
        spinnerSize: 20,
        raised: true,
        stroked: false,
        spinnerColor: 'accent',
        fullWidth: true,
        disabled: this.flag,
        mode: 'indeterminate',
        customClass: 'stop-class',
    };

    startAllButtonOptions: MatProgressButtonOptions = {
        active: false,
        text: 'Start',
        spinnerSize: 20,
        raised: true,
        stroked: false,
        spinnerColor: 'accent',
        fullWidth: true,
        disabled: false,
        mode: 'indeterminate',
        customClass: 'start-class',
    };

    stopAllButtonOptions: MatProgressButtonOptions = {
        active: false,
        text: 'Stop',
        spinnerSize: 20,
        raised: true,
        stroked: false,
        spinnerColor: 'accent',
        fullWidth: true,
        disabled: this.flag,
        mode: 'indeterminate',
        customClass: 'stop-class',
    };

    async ngOnInit() {
        this.rdsData = await this.ec2service.getRDSLastActivity();
        this.instancesList = await this.ec2service.getAllInstances();
        this.rdsData = this.rdsData.concat(this.instancesList);
        if(localStorage.getItem('RDSFirstLaunchTime') !== null){
            this.rdsData[0].LaunchTime = localStorage.getItem('RDSFirstLaunchTime');
        }
        this.currentBastionStatus();
        this.currentCplexStatus();
        this.currentUiStatus();
        this.currentRDSStatus();
        const uiResult = await this.ec2service.getInstanceStatus(environment.jisptUiInstanceId);
        const cplexResult = await this.ec2service.getInstanceStatus(environment.jisptCplexInstanceId);
        const bastionResult = await this.ec2service.getInstanceStatus(environment.jisptBastionInstanceId);
        this.rdsResponse= await this.ec2service.getRDSStatus('');
        if(sessionStorage.getItem("func") == "startAll"){
            this.startAll();
        }
        if(sessionStorage.getItem("func") == "stopAll"){
            this.stopAll();
        }
        if (uiResult) {
            this.flag = true;
            this.startUiButtonOptions.disabled = true;
            this.stopUiButtonOptions.disabled = false;
        } else {
            this.flag = false;
            this.startUiButtonOptions.disabled = false;
            this.stopUiButtonOptions.disabled = true;
        }
        if (cplexResult) {
            this.flag = true;
            this.startCplexButtonOptions.disabled = true;
            this.stopCplexButtonOptions.disabled = false;
        } else {
            this.flag = false;
            this.startCplexButtonOptions.disabled = false;
            this.stopCplexButtonOptions.disabled = true;
        }
        if (bastionResult) {
            this.flag = true;
            this.startBastionButtonOptions.disabled = true;
            this.stopBastionButtonOptions.disabled = false;
        } else {
            this.flag = false;
            this.startBastionButtonOptions.disabled = false;
            this.stopBastionButtonOptions.disabled = true;
        }

        if(this.rdsResponse.DBInstances[0].DBInstanceStatus!="stopped"){
         console.log(this.rdsResponse.DBInstances[0].DBInstanceStatus, localStorage.getItem("RDSFirstLaunchTime"));
           this.flag = true;
            this.startRDSButtonOptions.disabled = true;
            this.stopRDSButtonOptions.disabled = false;
        } else{
            this.flag = false;
            this.startRDSButtonOptions.disabled = false;
            this.stopRDSButtonOptions.disabled = true;
        }
        if(uiResult || bastionResult || cplexResult || this.rdsResponse.DBInstances[0].DBInstanceStatus!="stopped"){
            this.flag = false;
            this.startAllButtonOptions.disabled = true;
            this.stopAllButtonOptions.disabled = false;
        }
        else{
            this.flag = false;
            this.startAllButtonOptions.disabled = false;
            this.stopAllButtonOptions.disabled = true;
        }
    }

    manageUi(){
        this.isUi = true;
        this.isBastion = false;
        this.isCplex = false;
        this.isRDS = false;
        this.isAll = false;

    }

    manageBastion(){
        this.isUi = false;
        this.isBastion = true;
        this.isCplex = false;
        this.isRDS = false;
        this.isAll = false;

    }

    manageCplex(){
        this.isUi = false;
        this.isBastion = false;
        this.isCplex = true;
        this.isRDS = false;
        this.isAll = false;
    }
    
    manageRDS(){
        this.isUi = false;
        this.isRDS = true;
        this.isBastion = false;
        this.isCplex = false;
        this.isAll = false;
    }

    manageJispt(){
        this.isUi = false;
        this.isRDS = false;
        this.isBastion = false;
        this.isCplex = false;
        this.isAll = true;
    }

    /*currentAllStatus(){
    }*/

    currentRDSStatus(){
        var status = this.rdsData[0].State;
        if(status == 'available'){
            this.statusRdsValue = 100;
            this.statusRdsColor = '#00b33c';
        }
        if(status == 'stopped'){
            this.statusRdsValue = 100;
            this.statusRdsColor = '#ff3333';
        }
        this.rdsState = status; 
        if(status != 'stopped' && status != 'available'){
                     
               this.checkRDSStatus();
        } 
        if(status == 'available' && this.rdsStatusNotChanged == true ){
                     
               this.checkRDSStatus();
        }   
    }

    currentBastionStatus(){
        var status;
        for(var i=0; i<this.instancesList.length; i++){
            if(this.instancesList[i].Id == environment.jisptBastionInstanceId){
                     status = this.instancesList[i].State;
                     if(status == 'running'){
                               this.statusBastionValue = 100;
                               this.statusBastionColor = '#00b33c';
                     }
                     if(status == 'stopped'){
                               this.statusBastionValue = 100;
                               this.statusBastionColor = '#ff3333';
                     }
                     if(status != 'stopped' && status != 'running'){
                         
                         this.checkBastionStatus();
                     }
            }
        }
        this.bastionState = status; 
    }

    currentCplexStatus(){
        var status;
        for(var i=0; i<this.instancesList.length; i++){
            if(this.instancesList[i].Id == environment.jisptCplexInstanceId){
                     status = this.instancesList[i].State;
                     if(status == 'running'){
                               this.statusCplexValue = 100;
                               this.statusCplexColor = '#00b33c';
                     }
                     if(status == 'stopped'){
                               this.statusCplexValue = 100;
                               this.statusCplexColor = '#ff3333';
                     }
                     if(status != 'stopped' && status != 'running'){
                         
                         this.checkCplexStatus();
                     }
            }
        }
        this.cplexState = status; 
    }

    currentUiStatus(){
        var status;
        for(var i=0; i<this.instancesList.length; i++){
            if(this.instancesList[i].Id == environment.jisptUiInstanceId){
                     status = this.instancesList[i].State;
                     if(status == 'running'){
                               this.statusUiValue = 100;
                               this.statusUiColor = '#00b33c';
                     }
                     if(status == 'stopped'){
                               this.statusUiValue = 100;
                               this.statusUiColor = '#ff3333';
                     }
                     if(status != 'stopped' && status != 'running'){
                        
                         this.checkUiStatus();
                     }
            }
        }
        this.uiState = status; 
    }

    delay(ms: number) {
        return new Promise( resolve => setTimeout(resolve, ms) );
    }

    async listCalling(){
        this.rdsData = await this.ec2service.getRDSLastActivity();
        this.instancesList = await this.ec2service.getAllInstances();
        this.rdsData = this.rdsData.concat(this.instancesList);
        if(localStorage.getItem('RDSFirstLaunchTime') !== null){
            this.rdsData[0].LaunchTime = localStorage.getItem('RDSFirstLaunchTime');
        }
        console.log(this.rdsData);
    }

    async checkBastionStatus(){
        this.statusBastionValue = 1;
        var status;
        for(var i=0; i<this.instancesList.length; i++){
            if(this.instancesList[i].Id == environment.jisptBastionInstanceId){
                     status = this.instancesList[i].State;
                     if(status == 'running')
                        await this.delay(10000);
                     if(status == 'stopped')
                        await this.delay(10000);
                     this.bastionState = status;  
                     while(status.length < 35){
                            this.instancesList = await this.ec2service.getAllInstances();
                            status =  this.instancesList[i].State;
                            if(status == 'pending'){
                                this.statusBastionValue = 50;
                                this.statusBastionColor = 'orange';
                                this.startBastionButtonOptions.disabled = true;
                                this.startAllButtonOptions.disabled = true;
                                this.stopBastionButtonOptions.disabled = true;
                                this.stopAllButtonOptions.disabled = true;
                            }
                            if(status == 'stopping'){
                                this.statusBastionValue = 30;
                                this.statusBastionColor = 'orange';
                                this.startBastionButtonOptions.disabled = true;
                                this.startAllButtonOptions.disabled = true;
                                this.stopBastionButtonOptions.disabled = true;
                                this.stopAllButtonOptions.disabled = true;
                            }
                            this.bastionState= status;
                            if(status == 'running'){
                               this.statusBastionValue = 100;
                               this.statusBastionColor = '#00b33c';
                               setTimeout(() => {
                                    this.startBastionButtonOptions.active = false;
                                    this.startAllButtonOptions.active = false;
                                    this.startBastionButtonOptions.disabled = true;
                                    this.startAllButtonOptions.disabled = true;
                                    this.stopBastionButtonOptions.disabled = false;
                                    this.stopAllButtonOptions.disabled = false;
                                }, 1000);
                               break;
                            }
                            if(status == 'stopped'){
                               this.statusBastionValue = 100;
                               this.statusBastionColor = '#ff3333';
                               setTimeout(() => {
                                    this.stopBastionButtonOptions.active = false;
                                    this.stopAllButtonOptions.active = false;
                                    this.stopBastionButtonOptions.disabled = true;
                                    this.stopAllButtonOptions.disabled = true;
                                    this.startBastionButtonOptions.disabled = false;
                                    this.startAllButtonOptions.disabled = false;
                                }, 1000);
                               break;
                            }
                            console.log(status);
                            await this.delay(3000);
                            if(sessionStorage.getItem("func") != "startAll" && sessionStorage.getItem("func") != "stopAll"){
                                this.listCalling();
                            }
                     }
            }
        }
    }

    async checkCplexStatus(){
        var status;
        this.statusCplexValue = 1;
        for(var i=0; i<this.instancesList.length; i++){
            if(this.instancesList[i].Id == environment.jisptCplexInstanceId){
                     status = this.instancesList[i].State;
                     if(status == 'running')
                        await this.delay(10000);
                     if(status == 'stopped')
                        await this.delay(10000);
                     this.cplexState = status;  
                     while(status.length < 35){
                            this.instancesList = await this.ec2service.getAllInstances();
                            status =  this.instancesList[i].State;
                            if(status == 'pending'){
                                this.statusCplexValue = 50;
                                this.statusCplexColor = 'orange';
                                this.startCplexButtonOptions.disabled = true;
                                this.startAllButtonOptions.disabled = true;
                                this.stopCplexButtonOptions.disabled = true;
                                this.stopAllButtonOptions.disabled = true;
                            }
                            if(status == 'stopping'){
                                this.statusCplexValue = 30;
                                this.statusCplexColor = 'orange';
                                this.startCplexButtonOptions.disabled = true;
                                this.startAllButtonOptions.disabled = true;
                                this.stopCplexButtonOptions.disabled = true;
                                this.stopAllButtonOptions.disabled = true;
                            }
                            this.cplexState= status;
                            if(status == 'running'){
                               this.statusCplexValue = 100;
                               this.statusCplexColor = '#00b33c';
                               setTimeout(() => {
                                     this.startCplexButtonOptions.active = false;
                                     this.startAllButtonOptions.active = false;
                                     this.startCplexButtonOptions.disabled = true;
                                     this.startAllButtonOptions.disabled = true;
                                     this.stopCplexButtonOptions.disabled = false;
                                     this.stopAllButtonOptions.disabled = false;
                               }, 1000);
                               break;
                            }
                            if(status == 'stopped'){
                               this.statusCplexValue = 100;
                               this.statusCplexColor = '#ff3333';
                               setTimeout(() => {
                                    this.stopCplexButtonOptions.active = false;
                                    this.stopAllButtonOptions.active = false;
                                    this.stopCplexButtonOptions.disabled = true;
                                    this.stopAllButtonOptions.disabled = true;
                                    this.startCplexButtonOptions.disabled = false;
                                    this.startAllButtonOptions.disabled = false;
                                }, 1000);
                               break;
                            }
                            console.log(status);
                            await this.delay(3000);
                            if(sessionStorage.getItem("func") != "startAll" && sessionStorage.getItem("func") != "stopAll"){
                                this.listCalling();
                            }
                     }
            }
        }
    }

    async checkUiStatus(){
        var status;
        this.statusUiValue = 1;
        for(var i=0; i<this.instancesList.length; i++){
            if(this.instancesList[i].Id == environment.jisptUiInstanceId){
                     status = this.instancesList[i].State;
                     if(status == 'running')
                        await this.delay(10000);
                     if(status == 'stopped')
                        await this.delay(10000);
                     this.uiState = status;  
                     while(status.length < 35){
                            this.instancesList = await this.ec2service.getAllInstances();
                            status =  this.instancesList[i].State;
                            if(status == 'pending'){
                                this.statusUiValue = 50;
                                this.statusUiColor = 'orange';
                                this.startUiButtonOptions.disabled = true;
                                this.startAllButtonOptions.disabled = true;
                                this.stopUiButtonOptions.disabled = true;
                                this.stopAllButtonOptions.disabled = true;
                            }
                            if(status == 'stopping'){
                                this.statusUiValue = 20;
                                this.statusUiColor = 'orange';
                                this.startUiButtonOptions.disabled = true;
                                this.startAllButtonOptions.disabled = true;
                                this.stopUiButtonOptions.disabled = true;
                                this.stopAllButtonOptions.disabled = true;
                            }
                            this.uiState= status;
                            if(status == 'running'){
                               this.statusUiValue = 100;
                               this.statusUiColor = '#00b33c';
                               setTimeout(() => {
                                    this.startUiButtonOptions.active = false;
                                    this.startAllButtonOptions.active = false;
                                    this.startUiButtonOptions.disabled = true;
                                    this.startAllButtonOptions.disabled = true;
                                    this.stopUiButtonOptions.disabled = false;
                                    this.stopAllButtonOptions.disabled = false;
                               }, 1000);
                               break;
                            }
                            if(status == 'stopped'){
                               this.statusUiValue = 100;
                               this.statusUiColor = '#ff3333';
                               setTimeout(() => {
                                    this.stopUiButtonOptions.active = false;
                                    this.stopAllButtonOptions.active = false;
                                    this.stopUiButtonOptions.disabled = true;
                                    this.stopAllButtonOptions.disabled = true;
                                    this.startUiButtonOptions.disabled = false;
                                    this.startAllButtonOptions.disabled = false;
                                }, 1000);
                               break;
                            }
                            console.log(status);
                            await this.delay(3000);
                            if(sessionStorage.getItem("func") != "startAll" && sessionStorage.getItem("func") != "stopAll"){
                               this.listCalling();
                            }
                     }
            }
        }
    }

    async checkRDSStatus(){
        this.statusRdsValue = 1;
        if(sessionStorage.getItem("RDSvalue") != "0"){
            console.log(sessionStorage.getItem("RDSvalue"));
            this.statusRdsValue = sessionStorage.getItem("RDSvalue");
        }
        var status = this.rdsData[0].State;
        console.log(status);
        if(status == 'stopped')
            await this.delay(10000);
        if(status=='available')
            await this.delay(10000);
        if(status=='running')
            await this.delay(10000);
        this.rdsState = status;
        console.log(status);
        this.statusRdsColor = 'orange';
        this.rdsData = await this.ec2service.getRDSLastActivity();
        var firstLaunchTime = this.rdsData[0].LaunchTime;
        console.log(firstLaunchTime);
        while(status.length < 35){
            console.log(status);
            this.rdsData = await this.ec2service.getRDSLastActivity();
            this.rdsData1 = await this.ec2service.getRDSLastActivity();
            this.instancesList = await this.ec2service.getAllInstances();
            this.rdsData = this.rdsData.concat(this.instancesList);
            if(localStorage.getItem('RDSFirstLaunchTime') !== null){
                this.rdsData[0].LaunchTime = localStorage.getItem('RDSFirstLaunchTime');
            }
            var secondValue = this.rdsData1[0].LaunchTime;
            status = this.rdsData[0].State;
            this.rdsState = status;
            if(firstLaunchTime.toString().includes(secondValue.toString())){
                console.log(firstLaunchTime, secondValue);
            }
            if(!firstLaunchTime.toString().includes(secondValue.toString())){
                console.log(firstLaunchTime, secondValue);
            }
            if(status=='available' && (firstLaunchTime.toString().includes(secondValue.toString()))){
                this.rdsStatusNotChanged = true;
            }
            if(status=='available' && (!firstLaunchTime.toString().includes(secondValue.toString()))){
                console.log(this.rdsData[0].LaunchTime);
                localStorage.setItem("RDSFirstLaunchTime", secondValue);
                this.rdsStatusNotChanged = false;
                this.statusRdsValue = 100;
                this.statusRdsColor = '#00b33c';
                setTimeout(() => {
                      this.startRDSButtonOptions.active = false;
                      this.startAllButtonOptions.active = false;
                      this.startRDSButtonOptions.disabled = true;
                      this.startAllButtonOptions.disabled = true;
                      this.stopRDSButtonOptions.disabled = false;
                      this.stopAllButtonOptions.disabled = false;
                }, 1000);
                break;
            }
            if(status=='stopped'){
                this.statusRdsValue = 100;
                this.statusRdsColor = '#ff3333';
                setTimeout(() => {
                      this.stopRDSButtonOptions.active = false;
                      this.stopAllButtonOptions.active = false;
                      this.stopRDSButtonOptions.disabled = true;
                      this.stopAllButtonOptions.disabled = true;
                      this.startRDSButtonOptions.disabled = false;
                      this.startAllButtonOptions.disabled = false;
                }, 1000);
                break;
            }
            if(status == 'configuring-enhanced-monitoring'){
                this.statusRdsValue = 80;
                this.statusRdsColor = 'orange';
                this.startRDSButtonOptions.disabled = true;
                this.startAllButtonOptions.disabled = true;
                this.stopRDSButtonOptions.disabled = true;
                this.stopAllButtonOptions.disabled = true;
            }
            else{
                await this.delay(10000);
                this.statusRdsValue++;
                sessionStorage.setItem("RDSvalue", this.statusRdsValue);
                this.statusRdsColor = 'orange';
                this.startRDSButtonOptions.disabled = true;
                this.startAllButtonOptions.disabled = true;
                this.stopRDSButtonOptions.disabled = true;
                this.stopAllButtonOptions.disabled = true;
            }
        }
        console.log("status completed",this.rdsData[0].LaunchTime, status);
        sessionStorage.setItem("RDSvalue", "0");
    }

    async startAll(){
        sessionStorage.setItem("func", "startAll");
        if(!this.startRDSButtonOptions.disabled){
            await this.start('jispt-rds');
        }
        if(!this.startBastionButtonOptions.disabled){
                this.start('bastion');
        }
        if(!this.startCplexButtonOptions.disabled){
                this.start('cplex');
        }
        if(!this.startUiButtonOptions.disabled){
                this.start('ui');
         }
         sessionStorage.setItem("func", " ");
    }

    async stopAll(){
        sessionStorage.setItem("func", "stopAll");
        if(!this.stopUiButtonOptions.disabled){
            this.stop('ui');
        }
        if(!this.stopCplexButtonOptions.disabled){
            this.stop('cplex');
        }
        if(!this.stopBastionButtonOptions.disabled){
            this.stop('bastion');
        }
        if(!this.stopRDSButtonOptions.disabled){
            console.log("stopping rds");
            await this.stop('jispt-rds');
        }
        this.listCalling();
        sessionStorage.setItem("func", " ");
    }

    async start(value) {
        if(value == 'ui'){
            this.startUiButtonOptions.active = true;
            this.startAllButtonOptions.active = true;
            this.startInstance(value);
            await this.checkUiStatus();
        }
        if(value == 'bastion'){
            this.startBastionButtonOptions.active = true;
            this.startAllButtonOptions.active = true;
            this.startInstance(value);
            await this.checkBastionStatus();
        }
        
        if(value == 'cplex'){
            this.startCplexButtonOptions.active = true;
            this.startAllButtonOptions.active = true;
            this.startInstance(value);
            await this.checkCplexStatus();
        }

        if(value == 'jispt-rds'){
            this.startRDSButtonOptions.active = true;
            this.startAllButtonOptions.active = true;
            this.startRDS(value);
            await this.checkRDSStatus();
        }
    }

    async stop(value){
        if(value == 'ui'){
            this.stopUiButtonOptions.active = true;
            this.stopAllButtonOptions.active = true;
            this.stopInstance(value);
            await this.checkUiStatus();
        
        }
        if(value == 'bastion'){
            this.stopBastionButtonOptions.active = true;
            this.stopAllButtonOptions.active = true;
            this.stopInstance(value);
            await this.checkBastionStatus();
        }
        if(value == 'cplex'){
            this.stopCplexButtonOptions.active = true;
            this.stopAllButtonOptions.active = true;
            this.stopInstance(value);
            await this.checkCplexStatus();
            
        }

         if(value == 'jispt-rds'){
             console.log("jispt rds is calling");
            this.stopRDSButtonOptions.active = true;
            this.stopAllButtonOptions.active = true;
            this.stopRDS(value);
            await this.checkRDSStatus();
            
        }
        
    }

    constructor(public dialog: MatDialog,public ec2service: EC2Service,public router: Router, public cwService: CloudWatchLogService, public datepipe: DatePipe) {
        console.log('Jispt Component');
    }

    getDate(timestamp: any) {
        const now = new Date(timestamp);
        return this.datepipe.transform(now, 'HH:MM:ss');
    }

    async ngAfterViewInit() {
        console.log(localStorage.getItem('group'));
        if (localStorage.getItem('group')) {
            this.logs = await this.cwService.getLogEntries(localStorage.getItem('group'), localStorage.getItem('stream'));
        } else {
            console.log('Please start or stop the server');
        }
    }

    async startInstance(value) {
        const data = await this.ec2service.manageInstance('start-ec2',value);
        console.log(await this.cwService.getLogEntries(data[0], data[1]));
        // const data = ['/aws/lambda/start-ec2', '2019/04/10/[$LATEST]181d5f3378bc4eda8932c9729dfa228b'];
        this.logs = await this.cwService.getLogEntries(data[0], data[1]);
        console.log(this.logs);
        localStorage.setItem('group', data[0]);
        localStorage.setItem('stream', data[1]);
    }

    async stopInstance(value) {
        const data = await this.ec2service.manageInstance('stop-ec2',value);
        console.log(await this.cwService.getLogEntries(data[0], data[1]));
        // const data = ['/aws/lambda/stop-ec2', '2019/04/10/[$LATEST]0cab1e2b1736480c87c7a63e155437f5'];
        this.logs = await this.cwService.getLogEntries(data[0], data[1]);
        console.log(this.logs);
        localStorage.setItem('group', data[0]);
        localStorage.setItem('stream', data[1]);
    }


    async stopRDS(value){
        const data =await this.ec2service.manageRDS('stop');

    }

    async startRDS(value){
        const data =await this.ec2service.manageRDS('start');

    }

    openStartDialog(value): void {
        this.buttonValue = null;
        if(value == 'ui'){
            this.startUiButtonOptions.active = true;
            this.startAllButtonOptions.active = true;
         
            const dialogRef = this.dialog.open(DialogOverview, {
              width: '250px',
              data: {buttonName:'Start',buttonValue: this.buttonValue}
            }
            );
            dialogRef.afterClosed().subscribe(result => {
                if(result != undefined){
                    this.buttonValue = result.toLowerCase();
                }
                else{
                    this.buttonValue = result;
                }
                console.log(this.buttonValue);
                if(this.buttonValue == 'start me'){
                      this.start('ui');
                }
                else{
                    setTimeout(() => {
                          this.startUiButtonOptions.active = false;
                          this.startAllButtonOptions.active = false;
                    }, 3000);
                }
            });
        }
        if(value == 'bastion'){
            this.startBastionButtonOptions.active = true;
            this.startAllButtonOptions.active = true;
         
            const dialogRef = this.dialog.open(DialogOverview, {
              width: '250px',
              data: {buttonName:'Start',buttonValue: this.buttonValue}
            }
            );
            dialogRef.afterClosed().subscribe(result => {
                if(result != undefined){
                    this.buttonValue = result.toLowerCase();
                }
                else{
                    this.buttonValue = result;
                }
                if(this.buttonValue == 'start me'){
                      this.start('bastion');
                }
                else{
                    setTimeout(() => {
                          this.startBastionButtonOptions.active = false;
                          this.startAllButtonOptions.active = false;
                    }, 3000);
                }
            });
        }
        if(value == 'cplex'){
            this.startCplexButtonOptions.active = true;
            this.startAllButtonOptions.active = true;
         
            const dialogRef = this.dialog.open(DialogOverview, {
              width: '250px',
              data: {buttonName:'Start',buttonValue: this.buttonValue}
            }
            );
            dialogRef.afterClosed().subscribe(result => {
                if(result != undefined){
                    this.buttonValue = result.toLowerCase();
                }
                else{
                    this.buttonValue = result;
                }
                if(this.buttonValue == 'start me'){

                      this.start('cplex');
                }
                else{
                    setTimeout(() => {
                          this.startCplexButtonOptions.active = false;
                          this.startAllButtonOptions.active = false;
                    }, 3000);
                }
            });
        }
        if(value == 'jispt-rds'){
            this.startRDSButtonOptions.active = true;
            this.startAllButtonOptions.active = true;
         
            const dialogRef = this.dialog.open(DialogOverview, {
              width: '250px',
              data: {buttonName:'Start',buttonValue: this.buttonValue}
            }
            );
            dialogRef.afterClosed().subscribe(result => {
                if(result != undefined){
                    this.buttonValue = result.toLowerCase();
                }
                else{
                    this.buttonValue = result;
                }
                if(this.buttonValue == 'start me'){

                      this.start('jispt-rds');
                }
                else{
                    setTimeout(() => {
                          this.startRDSButtonOptions.active = false;
                          this.startAllButtonOptions.active = false;
                    }, 3000);
                }
            });
        }
        if(value == 'start-all'){
            this.startUiButtonOptions.active = true;
            this.startBastionButtonOptions.active = true;
            this.startCplexButtonOptions.active = true;
            this.startRDSButtonOptions.active = true;
            this.startAllButtonOptions.active = true;
         
            const dialogRef = this.dialog.open(DialogOverview, {
              width: '250px',
              data: {buttonName:'Start',buttonValue: this.buttonValue}
            }
            );
            dialogRef.afterClosed().subscribe(result => {
                if(result != undefined){
                    this.buttonValue = result.toLowerCase();
                }
                else{
                    this.buttonValue = result;
                }
                if(this.buttonValue == 'start me'){

                      this.startAll();
                }
                else{
                    setTimeout(() => {
                          this.startUiButtonOptions.active = false;
                          this.startBastionButtonOptions.active = false;
                          this.startCplexButtonOptions.active = false;
                          this.startRDSButtonOptions.active = false;
                          this.startAllButtonOptions.active = false;
                    }, 3000);
                }
            });
        }
    }

    openStopDialog(value): void {
        this.buttonValue = null;
        if(value == 'ui'){
            this.stopUiButtonOptions.active = true;
            this.stopAllButtonOptions.active = true;
         
            const dialogRef = this.dialog.open(DialogOverview, {
              width: '250px',
              data: {buttonName:'Stop',buttonValue: this.buttonValue}
            }
            );
            dialogRef.afterClosed().subscribe(result => {
                if(result != undefined){
                    this.buttonValue = result.toLowerCase();
                }
                else{
                    this.buttonValue = result;
                }
                if(this.buttonValue == 'stop me'){
                      this.stop('ui');
                }
                else{
                    setTimeout(() => {
                          this.stopUiButtonOptions.active = false;
                          this.stopAllButtonOptions.active = false;
                    }, 3000);
                }
            });
        }
        if(value == 'bastion'){
            this.stopBastionButtonOptions.active = true;
            this.stopAllButtonOptions.active = true;
         
            const dialogRef = this.dialog.open(DialogOverview, {
              width: '250px',
              data: {buttonName:'Stop',buttonValue: this.buttonValue}
            }
            );
            dialogRef.afterClosed().subscribe(result => {
                if(result != undefined){
                    this.buttonValue = result.toLowerCase();
                }
                else{
                    this.buttonValue = result;
                }
                if(this.buttonValue == 'stop me'){
                      this.stop('bastion');
                }
                else{
                    setTimeout(() => {
                          this.stopBastionButtonOptions.active = false;
                          this.stopAllButtonOptions.active = false;
                    }, 3000);
                }
            });
        } 
        if(value == 'cplex'){
            this.stopCplexButtonOptions.active = true;
            this.stopAllButtonOptions.active = true;
         
            const dialogRef = this.dialog.open(DialogOverview, {
              width: '250px',
              data: {buttonName:'Stop',buttonValue: this.buttonValue}
            }
            );
            dialogRef.afterClosed().subscribe(result => {
                if(result != undefined){
                    this.buttonValue = result.toLowerCase();
                }
                else{
                    this.buttonValue = result;
                }
                if(this.buttonValue == 'stop me'){

                      this.stop('cplex');
                }
                else{
                    setTimeout(() => {
                          this.stopCplexButtonOptions.active = false;
                          this.stopAllButtonOptions.active = false;
                    }, 3000);
                }
            });
        }  
        if(value == 'jispt-rds'){
            this.stopRDSButtonOptions.active = true;
            this.stopAllButtonOptions.active = true;
         
            const dialogRef = this.dialog.open(DialogOverview, {
              width: '250px',
              data: {buttonName:'Stop',buttonValue: this.buttonValue}
            }
            );
            dialogRef.afterClosed().subscribe(result => {
                if(result != undefined){
                    this.buttonValue = result.toLowerCase();
                }
                else{
                    this.buttonValue = result;
                }
                if(this.buttonValue == 'stop me'){

                      this.stop('jispt-rds');
                }
                else{
                    setTimeout(() => {
                          this.stopRDSButtonOptions.active = false;
                          this.stopAllButtonOptions.active = false;
                    }, 3000);
                }
            });
        } 
        if(value == 'stop-all'){
            this.stopUiButtonOptions.active = true;
            this.stopBastionButtonOptions.active = true;
            this.stopCplexButtonOptions.active = true;
            this.stopRDSButtonOptions.active = true;
            this.stopAllButtonOptions.active = true;
         
            const dialogRef = this.dialog.open(DialogOverview, {
              width: '250px',
              data: {buttonName:'Stop',buttonValue: this.buttonValue}
            }
            );
            dialogRef.afterClosed().subscribe(result => {
                if(result != undefined){
                    this.buttonValue = result.toLowerCase();
                }
                else{
                    this.buttonValue = result;
                }
                if(this.buttonValue == 'stop me'){
                      console.log("stop all calling");
                      this.stopAll();
                }
                else{
                    setTimeout(() => {
                          this.stopUiButtonOptions.active = false;
                          this.stopBastionButtonOptions.active = false;
                          this.stopCplexButtonOptions.active = false;
                          this.stopRDSButtonOptions.active = false;
                          this.stopAllButtonOptions.active = false;
                    }, 3000);
                }
            });
        }
    }
}

//material dialog box for start and stop

@Component({
  template: `<div mat-dialog-content>
  <p *ngIf="data.buttonName=='Start'">If u want to start, type <br><b>"start me"</b> in the text box</p>
  <p *ngIf="data.buttonName=='Stop'">If u want to stop, type  <br><b>"stop me"</b> in the text box</p>
  <mat-form-field>
    <mat-label *ngIf="data.buttonName=='Start'">start me</mat-label>
    <mat-label *ngIf="data.buttonName=='Stop'">stop me</mat-label>
    <input matInput [(ngModel)]="data.buttonValue" (keyup)="onKey($event)">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">No Thanks</button>
  <button mat-button [mat-dialog-close]="data.buttonValue" [disabled]="isMatch != true">OK</button>
</div>`,
})
export class DialogOverview extends JisptComponent{

  isMatch : boolean = false;
  constructor(public dialog: MatDialog,public ec2service: EC2Service,public router: Router, public cwService: CloudWatchLogService, public datepipe: DatePipe,
    public dialogRef: MatDialogRef<DialogOverview>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
      super(dialog,ec2service,router,cwService,datepipe)
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onKey(event){
      console.log(event.target.value.toLowerCase());
      if(this.data.buttonName=='Start' && event.target.value.toLowerCase() == 'start me'){
          this.isMatch = true;
      }
      else if(this.data.buttonName=='Stop' && event.target.value.toLowerCase() == 'stop me'){
          this.isMatch = true;
      }
      else{
          this.isMatch = false;
      }
  }

}

// ProgressBarColor change based on status

@Directive({
  selector: '[appProgressBarColor]'
})
export class ProgressBarColor implements OnChanges{
  static counter = 0;

  @Input() appProgressBarColor;
  styleEl:HTMLStyleElement = document.createElement('style');
  
  //generate unique attribule which we will use to minimise the scope of our dynamic style 
  uniqueAttr = `app-progress-bar-color-${ProgressBarColor.counter++}`;

  constructor(private el: ElementRef) { 
    const nativeEl: HTMLElement = this.el.nativeElement;
    nativeEl.setAttribute(this.uniqueAttr,'');
    nativeEl.appendChild(this.styleEl);
  }

  ngOnChanges(changes: SimpleChanges): void{
    this.updateColor();
  }

  updateColor(): void{
    // update dynamic style with the uniqueAttr
    this.styleEl.innerText = `
      [${this.uniqueAttr}] .mat-progress-bar-fill::after {
        background-color: ${this.appProgressBarColor};
      }
    `;
  }

}

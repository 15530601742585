import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {UserRegistrationService} from '../../../service/user-registration.service';
import {CognitoCallback} from '../../../service/cognito.service';
import { RegistrationConfirmationComponent } from '../confirm/confirm-registration.component';

export class RegistrationUser {
    name: string;
    email: string;
    phone_number: string;
    password: string;
}

@Component({
    selector: 'app-register',
    templateUrl: './registration.component.html',
    styleUrls: ['../auth.component.scss']
})
export class RegisterComponent implements CognitoCallback {
    registrationUser: RegistrationUser;
    router: Router;
    errorMessage: string;

    constructor(public userRegistration: UserRegistrationService, router: Router) {
        this.router = router;
        this.onInit();
    }

    onInit() {
        this.registrationUser = new RegistrationUser();
        this.errorMessage = null;
    }

    onRegister() {
        // tslint:disable-next-line:max-line-length
        if (this.registrationUser.name == null || this.registrationUser.email == null || this.registrationUser.phone_number == null || this.registrationUser.password == null) {
            this.errorMessage = 'All fields are required';
            return;
        }
        this.errorMessage = null;
        this.userRegistration.register(this.registrationUser, this);
    }

    cognitoCallback(message: string, result: any) {
        if (message != null) {
            this.errorMessage = message;
            console.log('result: ' + this.errorMessage);
        } else {
            console.log('redirecting');
            this.router.navigate(['/home/confirmRegistration', result.user.username]);
            console.log(message);
        }
    }
}

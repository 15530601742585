export const environment = {
    production: false,

    access_key: 'AKIAYVR5N3FFNFSQ4PPO',
    secret_key: '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',
    region: 'us-east-1',

    ///////////////////////// WITH MFA //////////////////////////

    identityPoolId: 'us-east-1:7895c800-2ab0-4905-b60d-548af2c60d20',
    userPoolId: 'us-east-1_rJ9Yf2oDN',
    clientId: '3urpf9pore233vp10b1h4o7nio',


    ///////////////////////// WITHOUT MFA //////////////////////////

    // identityPoolId: 'us-east-1:d2837854-55da-4f51-a9f2-1fe7f32f5334',
    // userPoolId: 'us-east-1_sdo7ysclH',
    // clientId: '6kq16irj88tjof10esrd74et0c',
    jisptUiInstanceId: 'i-02ae3dc4baf5eb00d',
    jisptCplexInstanceId: 'i-09dbdc7016436fcbd',
    jisptBastionInstanceId: 'i-06f41f6ec6dce2b2e',
    rdsInstanceName:'qsibatch'
};
